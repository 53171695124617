import { UilAngleDoubleDown, UilAngleDoubleUp } from '@iconscout/react-unicons'
import { Flex, Group, Stack, Text } from '@mantine/core'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { queryStringify } from '../../../../api/utils'
import FileThumbnailPreview from '../../../../components/FileThumbnailPreview'
import OpportunityIcon from '../../../../components/OpportunityIcon'
import { TSOpportunitySeed } from '../../../../queries/allProjectsPostNtp/types'
import { getCombinedOpportunityAddress } from '../../../../queries/allProjectsPostNtp/utils'
import { truncateString } from '../../../../utils'

interface ChildProjectTypes {
  childProjects: TSOpportunitySeed[]
}

const ChildProjects = ({ childProjects }: ChildProjectTypes) => {
  const navigate = useNavigate()

  const [childProjectsToShow, setChildProjectsToShow] = useState(
    childProjects.length > 2 ? childProjects.slice(0, 2) : childProjects
  )

  return (
    <Stack my='xl' gap='lg'>
      <Group justify='space-between' h={30}>
        <Text c='black' fw='700'>
          {`${
            childProjects?.length ? childProjects.length : 'No'
          } Child Projects`}
        </Text>
      </Group>
      {childProjects?.length != 0 && (
        <Flex direction='column' gap='md'>
          {childProjectsToShow?.map((childSeed) => (
            <FileThumbnailPreview
              onClick={() => {
                navigate(
                  `${location?.pathname}?${queryStringify({
                    opportunityId: childSeed.id,
                    opportunityType: 'seed',
                    customerId: childSeed.customerId,
                  })}`
                )
              }}
              title={
                <Group>
                  <Text
                    fw='600'
                    lh='normal'
                    lineClamp={2}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {truncateString(
                      `${childSeed.ecmType} - ${childSeed.displayId}`,
                      100
                    ).toUpperCase()}
                  </Text>
                </Group>
              }
              key={childSeed.id}
              fileIcon={
                <OpportunityIcon ecmType={childSeed.ecmType} iconSize='32' />
              }
              metaText={[
                getCombinedOpportunityAddress(childSeed).toUpperCase(),
              ]}
            />
          ))}
        </Flex>
      )}
      {childProjects.length > 2 && (
        <Group
          gap='0px'
          align='center'
          onClick={() =>
            setChildProjectsToShow(
              childProjectsToShow.length > 2
                ? childProjects.slice(0, 2)
                : childProjects
            )
          }
          style={{ cursor: 'pointer' }}
          w='max-content'
        >
          <Text c='blue.6'>
            {childProjectsToShow.length > 2
              ? `Show fewer projects`
              : `Show all ${childProjects.length} projects`}
          </Text>
          {childProjectsToShow.length > 2 ? (
            <UilAngleDoubleUp color='#43558E' />
          ) : (
            <UilAngleDoubleDown color='#43558E' />
          )}
        </Group>
      )}
    </Stack>
  )
}

export default ChildProjects
