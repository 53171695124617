import { Box, Checkbox, Divider, Flex } from '@mantine/core'
import { useRollbarContext } from '@rollbar/react'
import debounce from 'debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import FiltersSection from './components/FiltersSection'
import OpportunityKanbanTable from './components/OpportunityKanbanTable'
import OpportunityListTable from './components/OpportunityListTable'
import {
  ButtonsWrapper,
  EmptySectionToBeOverlapped,
  FilterSectionWrapper,
  FloatingButtonsWrapper,
  MessageWrapperStyled,
  NoResultsWrapper,
  PageWrapperStyled,
  SearchFilterInput,
  TableSectionWrapper,
  TotalResultCountWrapper,
} from './styles'
import { queryStringify } from '../../../api/utils'
import Button from '../../../components/Button'
import CardIcon from '../../../components/Icons/cardIcon'
import ListViewIcon from '../../../components/Icons/ListViewIcon'
import SegmentedControl from '../../../components/mantine/SegmentedControl'
import Message from '../../../components/Message'
import PageHeader from '../../../components/PageHeader'
import Spinner from '../../../components/Spinner'
import { Option } from '../../../components/SwitchButton'
import { REDAPTIVE_ONE_PAGE_TITLE } from '../../../constants/strings'
import { actions as modalActions } from '../../../ducks/modal'
import { useGainsightTracking } from '../../../gainsight/allProjectsPostNtp'
import { useFilterStore } from '../../../globalState/allProjectsPostNtp'
import { useCurrentCustomerStore } from '../../../globalState/currentCustomer'
import { useDocumentTitle } from '../../../hooks/useSetDocumentTitle'
import {
  useAssignLabels,
  useCreateBatch,
  useFetchAllBatches,
  useFetchAllProjects,
  useFetchCustomerSettings,
  useFetchLabels,
  useFetchMLOV,
  useFetchUserEmails,
  useRejectBatch,
  useUpdateBatchFollowers,
  useUpdateCustomerSettings,
} from '../../../queries/allProjectsPostNtp'
import {
  TSAddLabelsRequestPayload,
  TSCustomerSettings,
  TSOpportunityBase,
  TSOpportunityEntityType,
  TSProjectStage,
} from '../../../queries/allProjectsPostNtp/types'
import {
  combineSeedsAndBatches,
  getSeedsThatMatchFilter,
  getSelectedSeeds,
} from '../../../queries/allProjectsPostNtp/utils'
import {
  getCurrencyFormat,
  locationSearchToObject,
  pluralize,
} from '../../../utils'

const AllProjectsPostNtp = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useRollbarContext('Projects')
  const { trackUpdateSearchTerm } = useGainsightTracking()
  const { currentCustomerId } = useCurrentCustomerStore()

  useFetchMLOV(currentCustomerId)
  const { data: customerSettings, isFetching: customerSettingsLoading } =
    useFetchCustomerSettings(currentCustomerId)
  const { data: userEmails, isFetching: userEmailsLoading } =
    useFetchUserEmails(currentCustomerId)

  const {
    data: allProjects,
    isFetching: allProjectsLoading,
    isError: allProjectsError,
  } = useFetchAllProjects(currentCustomerId)

  const {
    data: batches,
    isFetching: batchesLoading,
    isError: batchesError,
  } = useFetchAllBatches(currentCustomerId)

  const {
    data: labels,
    isFetching: labelsLoading,
    isError: labelsError,
  } = useFetchLabels(currentCustomerId)

  const {
    mutate: assignLabels,
    isPending: assignLabelsLoading,
    isError: assignLabelsError,
  } = useAssignLabels()

  const { mutate: updateCustomerSettings } = useUpdateCustomerSettings()
  const {
    mutate: createBatch,
    isPending: createBatchLoading,
    isError: createBatchError,
  } = useCreateBatch()
  const { mutate: updateBatchFollowers } = useUpdateBatchFollowers()

  const { isPending: rejectBatchLoading, isError: rejectBatchError } =
    useRejectBatch()

  const { filters, selectedSeeds, setSelectedSeeds, setFilters, resetFilters } =
    useFilterStore()

  // using the url params to indicate if there is a detail to load
  const [urlParamsChecked, setUrlParamsChecked] = useState(false)
  const locationSearchObj = locationSearchToObject<{
    opportunityId: string
    opportunityType: TSOpportunityEntityType
  }>(location.search)

  const deepLinkOpportunityId = useMemo(
    () => locationSearchObj.opportunityId,
    [locationSearchObj.opportunityId]
  )
  const deepLinkOpportunityType = useMemo(
    () => locationSearchObj.opportunityType,
    [locationSearchObj.opportunityType]
  )
  useEffect(() => {
    if (
      !allProjectsLoading &&
      !batchesLoading &&
      allProjects &&
      batches &&
      (allProjects.length > 0 || batches.length > 0) &&
      !urlParamsChecked
    ) {
      if (deepLinkOpportunityId && deepLinkOpportunityType) {
        dispatch(
          modalActions.showAllProjectsPostNtpDetailModal({
            opportunitySeedId: deepLinkOpportunityId,
            opportunityType: deepLinkOpportunityType,
          })
        )
      }
      setUrlParamsChecked(true)
    }
  }, [
    dispatch,
    allProjects,
    batches,
    allProjectsLoading,
    batchesLoading,
    urlParamsChecked,
    deepLinkOpportunityId,
    deepLinkOpportunityType,
  ])

  useEffect(() => {
    setUrlParamsChecked(false) // when the ids change, we'll need to redo the check above
  }, [deepLinkOpportunityId, deepLinkOpportunityType])

  useEffect(() => {
    resetFilters(allProjects)
  }, [allProjects])

  const opportunitiesWithBatches = useMemo(() => {
    if (allProjects?.length || batches?.length) {
      return combineSeedsAndBatches(allProjects, batches)
    }
    return []
  }, [allProjects, batches])
  const tableData = useMemo(() => {
    if (opportunitiesWithBatches.length) {
      return getSeedsThatMatchFilter(
        opportunitiesWithBatches,
        filters,
        labels,
        selectedSeeds
      )
    }
    return []
  }, [opportunitiesWithBatches, filters, labels])

  const selectedData = useMemo(() => {
    if (opportunitiesWithBatches.length) {
      return getSelectedSeeds(
        opportunitiesWithBatches,
        filters,
        labels,
        selectedSeeds
      )
    }
    return []
  }, [opportunitiesWithBatches, filters, labels, selectedSeeds])

  const handleOpportunitySeedClick = (
    opportunity: TSOpportunityBase,
    shouldFocusToComments?
  ) => {
    navigate(
      `${location?.pathname}?${queryStringify({
        opportunityId: opportunity.id,
        opportunityType: opportunity.entityType,
        customerId: opportunity.customerId,
        shouldFocusToComments: shouldFocusToComments,
      })}`
    )
  }

  const handleCreateBatchClick = () => {
    dispatch(
      modalActions.showOpportunityCreateBatchModal({
        preselectedFollowers: getSelectedFollowersList(),
        formattedUserEmails,
        userEmailsLoading,
        handleCreateBatch,
        isBatchNameTaken,
        handleClosePopup,
      })
    )
  }

  const handleAddLabelsClick = () => {
    dispatch(
      modalActions.showOpportunityAddLabelsModal({
        labels,
        labelsLoading,
        handleAddLabels,
        handleClosePopup,
      })
    )
  }

  const handleEditCustomerSettings = (customerSettings: TSCustomerSettings) => {
    updateCustomerSettings({
      customerId: currentCustomerId,
      customerSettings,
    })
  }

  const isBatchNameTaken = (batchTitle: string): boolean => {
    return !!batches?.filter(
      (batch) => batch.title.toLowerCase() == batchTitle.toLowerCase()
    ).length
  }

  const handleCreateBatch = (
    batchTitle: string,
    batchDescription: string,
    projectStage: TSProjectStage,
    selectedFollowers: string[]
  ) => {
    createBatch(
      {
        title: batchTitle,
        description: batchDescription,
        projectStage,
        customerId: currentCustomerId,
        opportunityIds: selectedData?.map((item) => item.id),
        followers: selectedFollowers,
      },
      {
        onSuccess: (createdBatch) => {
          if (selectedFollowers.length) {
            updateBatchFollowers({
              batchId: createdBatch.id,
              followers: selectedFollowers,
            })
          }
        },
      }
    )
    handleClosePopup()
  }

  const handleClosePopup = () => {
    dispatch(modalActions.hideModal())
  }

  const handleToggleSelectedRows = (e) => {
    const isChecked = e.target.checked
    setFilters({
      ...filters,
      selectedOnly: isChecked,
    })
  }

  const handleFilterInput = (event) => {
    const searchKey = event.target.value.toLowerCase()
    setFilters({
      ...filters,
      searchKey: searchKey,
    })
    trackUpdateSearchTerm(searchKey)
  }

  // TODO: replace w/ actual site values when moving past prototype:
  const currencyFormat = useMemo(() => {
    return getCurrencyFormat('en-US', 'USD', 0)
  }, [])

  const handleAddLabels = (
    formattedLabels: { id?: string; text: string }[]
  ) => {
    const addLabelsRequestPayload: TSAddLabelsRequestPayload = {
      customerId: currentCustomerId,
      labels: formattedLabels,
      opportunityIds: allProjects
        ?.filter(
          (seed) => selectedData?.map((item) => item.id).includes(seed.id)
        )
        ?.map((seed) => seed.id),
      batchIds: batches
        ?.filter(
          (batch) => selectedData?.map((item) => item.id).includes(batch.id)
        )
        ?.map((seed) => seed.id),
    }
    assignLabels(addLabelsRequestPayload)
    handleClosePopup()
  }

  /**** begin card layout changes */

  const [layout, setLayout] = useState<'table' | 'card'>('table')

  const viewOptions = useMemo(() => {
    const tableIconColor = layout === 'table' ? '#fff' : '#6C6D6E'
    const cardIconColor = layout === 'card' ? '#fff' : '#6C6D6E'
    return ['table', 'card']?.map((option) => ({
      label: (
        <div style={{ height: '20px' }}>
          {option === 'table' ? (
            <ListViewIcon color={tableIconColor} />
          ) : (
            <CardIcon color={cardIconColor} />
          )}
        </div>
      ),
      value: option,
      gainsightTagId: 'opportunities-toggle-layout-' + option,
    })) as Option[]
  }, [layout])

  const handleLayoutToggle = (value) => {
    setLayout(value)
  }

  function renderLayout() {
    switch (layout) {
      case 'table':
        return (
          <OpportunityListTable
            tableData={tableData}
            labels={labels}
            handleChangeSelectedOpportunities={handleRowSelectionChange}
            handleViewDetails={handleOpportunitySeedClick}
          />
        )
      case 'card':
        return (
          <OpportunityKanbanTable
            opportunities={tableData}
            labels={labels}
            customerSettings={customerSettings}
            handleChangeSelectedOpportunities={handleRowSelectionChange}
            handleViewDetails={handleOpportunitySeedClick}
            handleEditCustomerSettings={handleEditCustomerSettings}
            currencyFormat={currencyFormat}
            isReadOnly
          />
        )
    }
  }

  /***** end card layout changes  */

  useDocumentTitle(`All Projects | ${REDAPTIVE_ONE_PAGE_TITLE}`)

  const handleRowSelectionChange = useCallback(
    (rowIds) => {
      setSelectedSeeds(rowIds)
    },
    [dispatch]
  )

  const isCreateBatchButtonVisible = useMemo(() => {
    if (
      selectedData.some((item) => item.entityType === 'batch') ||
      selectedData.length < 2
    ) {
      return false
    } else return true
  }, [selectedData])

  const formattedUserEmails = userEmails?.map((user) => ({
    label: user.email,
    value: user.email,
  }))

  const getSelectedFollowersList = (): string[] => {
    if (isCreateBatchButtonVisible) {
      return Array.from(
        new Set(
          selectedData
            ?.map((seed) => seed.followers)
            .flat()
            ?.filter((email) => email !== '')
        )
      )
    } else return []
  }

  const isPageDataLoading = () => {
    if (
      allProjectsLoading ||
      customerSettingsLoading ||
      rejectBatchLoading ||
      batchesLoading ||
      labelsLoading ||
      assignLabelsLoading ||
      createBatchLoading
    ) {
      return true
    } else {
      return false
    }
  }

  const projectCountLabel = pluralize(tableData.length, 'Project', 'Projects')

  const [seedsContainerHeight, setSeedsContainerHeight] = useState(
    'calc(100vh - 380px)'
  )

  return (
    <PageWrapperStyled seedsContainerHeight={seedsContainerHeight}>
      <PageHeader Title='All Projects' />
      <Divider />
      <Box mx='24px'>
        <FiltersSection
          opportunitySeeds={allProjects || []}
          labels={labels || []}
          setSeedsContainerHeight={setSeedsContainerHeight}
        />
      </Box>
      <Divider />
      <Box mx='24px'>
        {allProjectsError && (
          <MessageWrapperStyled>
            <Message type='error'>{allProjectsError}</Message>
          </MessageWrapperStyled>
        )}
        {batchesError && (
          <MessageWrapperStyled>
            <Message type='error'>{batchesError}</Message>
          </MessageWrapperStyled>
        )}
        {labelsError && (
          <MessageWrapperStyled>
            <Message type='error'>{labelsError}</Message>
          </MessageWrapperStyled>
        )}
        {assignLabelsError && (
          <MessageWrapperStyled>
            <Message type='error'>assignLabelsError</Message>
          </MessageWrapperStyled>
        )}
        {createBatchError && (
          <MessageWrapperStyled>
            <Message type='error'>{createBatchError}</Message>
          </MessageWrapperStyled>
        )}
        {rejectBatchError && (
          <MessageWrapperStyled>
            <Message type='error'>rejectBatchError</Message>
          </MessageWrapperStyled>
        )}
        {labelsError && (
          <MessageWrapperStyled>
            <Message type='error'>{labelsError}</Message>
          </MessageWrapperStyled>
        )}
      </Box>

      {!isPageDataLoading() && opportunitiesWithBatches.length > 0 && (
        <Box mx='24px'>
          <TableSectionWrapper>
            <FilterSectionWrapper theme={theme}>
              <TotalResultCountWrapper>
                {tableData.length} {projectCountLabel}
              </TotalResultCountWrapper>
              <SearchFilterInput
                placeholder='Type to search by name, address, label or ID'
                onChange={debounce(handleFilterInput, 300)}
                defaultValue={filters.searchKey}
              />
              <ButtonsWrapper>
                <Checkbox
                  disabled={!filters.selectedOnly && selectedData.length == 0}
                  onChange={(e) => handleToggleSelectedRows(e)}
                  label='Selected'
                  checked={filters.selectedOnly}
                  gainsightTagId='opportunities-filter-selected'
                />
                <SegmentedControl
                  activeOption={layout}
                  options={viewOptions}
                  updateOption={handleLayoutToggle}
                  color='#485DA0'
                />
                <EmptySectionToBeOverlapped />
              </ButtonsWrapper>
            </FilterSectionWrapper>
            {tableData.length ? (
              renderLayout()
            ) : (
              <NoResultsWrapper>No Results</NoResultsWrapper>
            )}
          </TableSectionWrapper>
        </Box>
      )}
      {!isPageDataLoading() && opportunitiesWithBatches.length == 0 && (
        <Box mx='24px'>
          <div>
            We have no available opportunities to show at this time. Please
            contact your Redaptive representative for more information.
          </div>
        </Box>
      )}

      {isPageDataLoading() && (
        <Flex
          h='100%'
          direction='column'
          justify='center'
          align='center'
          mt='20px'
        >
          <Spinner />
        </Flex>
      )}

      <FloatingButtonsWrapper>
        {isCreateBatchButtonVisible && (
          <Button
            borderRadius='small'
            buttonType='secondary'
            onClick={() => handleCreateBatchClick()}
          >
            Create Batch
          </Button>
        )}
        {selectedData.length >= 1 && (
          <Button
            borderRadius='small'
            buttonType='secondary'
            onClick={() => handleAddLabelsClick()}
          >
            Add labels
          </Button>
        )}
        {layout != 'card' && selectedData.length == 1 && (
          <Button
            borderRadius='small'
            buttonType='secondary'
            onClick={() =>
              selectedData[0]
                ? handleOpportunitySeedClick(selectedData[0])
                : null
            }
          >
            {`View Details `}
          </Button>
        )}
      </FloatingButtonsWrapper>
    </PageWrapperStyled>
  )
}

export default AllProjectsPostNtp
