import { useHotkeys } from '@mantine/hooks'
import { lazy, Suspense, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import 'regenerator-runtime/runtime'
import styled, { createGlobalStyle } from 'styled-components'

import DataExplorer from './DataExplorer'
import AllProjectsPostNtp from './Projects/AllProjectsPostNtp'
import { useCustomerSelection } from './useCustomerSelection'
import useSetGainsightContext from './useCustomerSwitch'
import useRedirectLegacyPaths from './useRedirectLegacyPaths'
import './yupDefaultMessages'
import ModalContainer from '../components/GlobalModal/Container'
import {
  ALL_PROJECTS_POST_NTP_PATH,
  DATA_EXPLORER_PATH,
  PORTFOLIO_PATH,
  PROJECTS_PATH,
} from '../constants/paths'
import PrivateRoutes from '../routes/PrivateRoutes'

const AccountPage = lazy(() => import('./Account'))
const HomePage = lazy(() => import('./HomePage'))
const LoginPage = lazy(() => import('./Login'))
const ResetPasswordPage = lazy(() => import('./Login/ResetPasswordPage'))
const SignupPage = lazy(() => import('./Login/SignupPage'))
const LogoutPage = lazy(() => import('./LogoutPage'))
const Projects = lazy(() => import('./Projects'))
const Sustainability = lazy(() => import('./Sustainability'))
const Alerts = lazy(() => import('./Alerts'))
const VariantsPage = lazy(() => import('./VariantsPage'))
const DesignLibrary = lazy(() => import('./DesignLibrary'))
const OpportunitiesPage = lazy(
  () => import('./Opportunities/OpportunitiesPage')
)

interface TSLocationState {
  message: string
}

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 55%;
`

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fontFamily};
  }

  * {
    box-sizing: border-box;
  }

  color: ${({ theme }) => theme.colors.fontMain};

  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  #apt-widget iframe.apt-widget-icon-frame {
    display: none;
  }
  #apt-widget #apt-widget-controller.apt-widget-dragging-element.dragging-element {
    z-Index: 1000;
  }

  /* Mantine notification style not working */
  .mantine-Notification-root+.mantine-Notification-root {
    margin-top: var(--mantine-spacing-md);
  }
`

const ReactQueryDevtoolsProduction = lazy(() =>
  // eslint-disable-next-line import/extensions
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)
const App = () => {
  useCustomerSelection()
  useRedirectLegacyPaths()
  useSetGainsightContext()
  const [searchParams] = useSearchParams()
  const [showDevtools, setShowDevtools] = useState(false)

  // cmd+U (windows: ctrl+U) to toggle react-query devtools in qa, stage, prod
  useHotkeys([
    [
      'mod+U',
      () => {
        setShowDevtools((prev) => !prev)
      },
    ],
  ])
  const location = useLocation()
  const state: TSLocationState = location.state as TSLocationState
  const unauthorizedMessage =
    location?.pathname === '/login' && state ? state.message : ''

  return (
    <div>
      <GlobalStyle />
      <ModalContainer />
      <Suspense>
        <Routes>
          <Route element={<PrivateRoutes />}>
            {/* Legacy Redirects */}
            <Route
              path='data-explorer/basic-consumption'
              element={
                <Navigate
                  to={`${DATA_EXPLORER_PATH}/consumption-analysis?${searchParams.toString()}`}
                />
              }
            />

            {/* Routes */}
            <Route path='' element={<HomePage />} />
            <Route path={`${PORTFOLIO_PATH}/*`} element={<Sustainability />} />
            <Route path={PROJECTS_PATH} element={<OpportunitiesPage />} />
            <Route
              path={ALL_PROJECTS_POST_NTP_PATH}
              element={<AllProjectsPostNtp />}
            />
            <Route path='projects/*' element={<Projects />} />
            <Route path='data-explorer/:pageId' element={<DataExplorer />} />
            <Route path='alerts' element={<Alerts />} />
            <Route path='account/*' element={<AccountPage />} />
            <Route path='variants' element={<VariantsPage />} />
          </Route>
          <Route path='design-library/*' element={<DesignLibrary />} />

          <Route
            path='reset-password/:resetId'
            element={<ResetPasswordPage />}
          />
          <Route path='signup/:signupId' element={<SignupPage />} />
          <Route
            path='login/*'
            element={<LoginPage message={unauthorizedMessage} />}
          />
          <Route path='logout' element={<LogoutPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </Suspense>
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </div>
  )
}

export default App
