import { Flex } from '@mantine/core'
import { useContext } from 'react'

import { useOnboardingAccountDataQuery } from '../../../queries/energyStar'
import Spinner from '../../Spinner'
import {
  ESOnboardingContext,
  SubModalHeading,
  SubModalSubHeading,
} from '../OnboardingModal'

const Confirmation = () => {
  const { formik } = useContext(ESOnboardingContext)
  const onboardingDataQuery = useOnboardingAccountDataQuery({
    currentAccountId: formik?.values?.accountId,
  })

  if (onboardingDataQuery?.isPending) {
    return (
      <Flex justify='center' align='center'>
        <Spinner size='sm' />
      </Flex>
    )
  }

  return (
    <>
      <SubModalHeading>Success!</SubModalHeading>
      <SubModalSubHeading>
        Our system detected{' '}
        <b>{onboardingDataQuery.data?.numberOfInactiveProperties} properties</b>{' '}
        that have been shared with Redaptive from your Energy Star Portfolio
        Manager Account.
      </SubModalSubHeading>
    </>
  )
}

export default Confirmation
