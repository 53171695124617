declare global {
  interface Window {
    aptrinsic: (event: string, eventName: string, payload: any) => void
  }
}

export const useGainsightTracking = () => {
  const trackEvent = (eventName: string, payload: any) => {
    if (typeof window !== 'undefined' && window.aptrinsic) {
      window.aptrinsic('track', eventName, payload)
    }
  }

  const trackUpdateProjectFilters = (payload: any) => {
    trackEvent('All Projects: Update Filter', payload)
  }

  const trackUpdateSearchTerm = (searchTerm: string) => {
    trackEvent('All Projects: Update Search Term', { searchTerm })
  }

  const trackUpdateProjectBookmark = (
    projectOrBatch: 'PROJECT' | 'BATCH',
    id: string,
    isBookmarked: boolean
  ) => {
    trackEvent('All Projects: Update Bookmark', {
      projectOrBatch,
      id,
      isBookmarked,
    })
  }
  const trackViewProjectDetails = (
    opportunitySeedId: string,
    opportunityType: string
  ) => {
    trackEvent('All Projects: View Details', {
      opportunitySeedId,
      opportunityType,
    })
  }

  const trackCreateProjectComment = (payload: any) => {
    trackEvent('All Projects: Add Comment', payload)
  }

  const trackEditProjectTitle = (
    seedOrBatch: 'PROJECT' | 'BATCH',
    id: string,
    title: string
  ) => {
    trackEvent('All Projects: Edit Title', { seedOrBatch, id, title })
  }

  const trackEditProjectDescription = (
    seedOrBatch: 'PROJECT' | 'BATCH',
    id: string,
    description: string
  ) => {
    trackEvent('All Projects: Edit Description', {
      seedOrBatch,
      id,
      description,
    })
  }

  const trackDeleteProjectAttachment = (payload: any) => {
    trackEvent('All Projects: Delete Attachment', payload)
  }

  const trackCreateProjectBatch = (payload: any) => {
    trackEvent('All Projects: Create Batch', payload)
  }

  const trackCreateProjectSuccess = (payload: any) => {
    trackEvent('All Projects: Create Project', payload)
  }

  const trackUpdateProject = (payload: any) => {
    trackEvent('All Projects: Update Project', payload)
  }

  const trackAddRemoveProjectToBatch = (
    batchId: string,
    projectId: string,
    action: 'add' | 'remove'
  ) => {
    const eventName =
      action === 'add'
        ? 'All Projects: Add to Batch'
        : 'All Projects: Remove from Batch'
    trackEvent(eventName, { batchId, projectId })
  }

  const trackAddProjectLabels = (payload: any) => {
    trackEvent('All Projects: Add Labels', payload)
  }

  const trackRemoveProjectLabel = (
    seedOrBatch: 'PROJECT' | 'BATCH',
    id: string,
    removedLabelId: string
  ) => {
    trackEvent('All Projects: Remove Label', {
      seedOrBatch,
      id,
      removedLabelId,
    })
  }

  const trackTransitionAllProjectsProjectStageInKanban = (
    seedOrBatch: 'PROJECT' | 'BATCH',
    id: string,
    oldProjectStage: string,
    newProjectStage: string
  ) => {
    trackEvent('All Projects: Change Project Stage in Kanban View', {
      seedOrBatch,
      id,
      oldProjectStage,
      newProjectStage,
    })
  }

  return {
    trackUpdateProjectFilters,
    trackUpdateSearchTerm,
    trackUpdateProjectBookmark,
    trackViewProjectDetails,
    trackCreateProjectComment,
    trackEditProjectTitle,
    trackEditProjectDescription,
    trackDeleteProjectAttachment,
    trackCreateProjectBatch,
    trackCreateProjectSuccess,
    trackUpdateProject,
    trackAddRemoveProjectToBatch,
    trackAddProjectLabels,
    trackRemoveProjectLabel,
    trackTransitionAllProjectsProjectStageInKanban,
  }
}
