import { UilSyncExclamation } from '@iconscout/react-unicons'
import { useDocumentTitle } from '@mantine/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

import ChampionTiles from './ChampionTiles'
import CalendarWithTime from '../../../components/Icons/CalendarWithTime'
import CalenderDisabledIcon from '../../../components/Icons/CalenderDisabledIcon'
import ESNoDataIcon from '../../../components/Icons/ESNoDataIcon'
import ListSelector from '../../../components/ListSelector'
import { useResourceNames } from '../../../components/mantine/Quantity/ResourceName'
import { QuantityTypes } from '../../../components/mantine/Quantity/types'
import { useUnit } from '../../../components/mantine/Quantity/Unit'
import RedaptiveReactTable7, {
  DefaultColumnFilter,
  ReactTableWrapperStyles,
} from '../../../components/RedaptiveReactTable7'
import Spinner from '../../../components/Spinner'
import Stack from '../../../components/Stack'
import { SUSTAINABILITY_REPORTING_PATH } from '../../../constants/paths'
import {
  ES_COORDINATOR_GROUP_ID,
  REDAPTIVE_ONE_PAGE_TITLE,
} from '../../../constants/strings'
import { selectSitesEntity, TSSiteTabs } from '../../../ducks/sites'
import {
  useEnergyStarSitesCountQuery,
  useOnboardingAccountDataQuery,
  useSubmissionDataQuery,
} from '../../../queries/energyStar'
import {
  ResourceNameEnum,
  TSMappedMeter,
} from '../../../queries/energyStar/types'
import { useUserHasPermissionGroup } from '../../../queries/permissions'
import {
  ALL_VALUE,
  siteNameFilterFunction,
} from '../../../utils/energyStarHelper'
import { getUrlSearchParams } from '../../../utils/getUrlSearchParams'

export const SUBMISSIONS_CREATE_DATE = 9

const MeterTypeToolipStyled = styled.div`
  text-align: left;
  p {
    margin: 0px;
  }
  ul {
    margin: 0px;
  }
`

export const meterTypeToolip = ({
  meterList,
}: {
  meterList: TSMappedMeter[]
}) => {
  return (
    <MeterTypeToolipStyled>
      <p>
        <b>Following Submissions were made to ENERGY STAR:</b>
      </p>
      <ul>
        {meterList.map(({ name, value, date }) => (
          <li key={name}>
            <u> {value}</u> submitted for {name} on {date}.
          </li>
        ))}
      </ul>
    </MeterTypeToolipStyled>
  )
}

export const valueNotSentTooltip = () => (
  <p>
    Consumption details were not sent to ENERGY STAR. Check with{' '}
    <a className='customer-mail-link' href='mailto:support@redaptiveinc.com'>
      Redaptive Customer Care
    </a>{' '}
    for details.
  </p>
)

const NoDataWrapper = styled.div`
  border-radius: 8px;
  height: 400px;
  border: 1px solid #e0e0e0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    margin-bottom: 20px;
  }
  p {
    margin: 0px;
    color: #6c6d6e;
    font-size: 14px;
    font-weight: 600;
  }
  b {
    color: #162447;
    font-size: 14px;
    font-weight: 600;
    text-decoration-line: underline;
  }
`

const StyledToolip = styled(Tooltip)`
  z-index: 100;
`

const ListSelectorStyled = styled(ListSelector)`
  text-align: left;
`

const TableCardTitleStyled = styled.h2`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  padding-left: 30px;
  position: absolute;
  margin-top: 24px;
`

export const CellStyled = styled.div`
  color: #162447;
  font-size: 14px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.28px;
`

export const CellStyledBold = styled(CellStyled)`
  font-weight: 600;
`

export const TableWrapper = styled.div<{
  isMenuOpen?: boolean
  resourceLength?: number
}>`
  position: relative;
  ${ReactTableWrapperStyles} {
    ${({ isMenuOpen, resourceLength }) =>
      isMenuOpen
        ? `min-height: ${Math.max(resourceLength || 0, 2) * 125}px;`
        : ''}
  }

  th#resourceName {
    z-index: 100;
  }

  .customer-mail-link {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }
`

const ESGReporting = () => {
  useDocumentTitle(`Sustainability Reporting | ${REDAPTIVE_ONE_PAGE_TITLE}`)
  const resourceNames = useResourceNames()
  const unit = useUnit()

  const [searchParams] = useSearchParams()
  // The serialize function here would be responsible for
  // creating an object of { key: value } pairs from the
  // fields in the form that make up the query.
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams)
  }, [searchParams])

  const { items: sites } = useSelector(selectSitesEntity) || {}

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { data: userIsEsCoordinator } = useUserHasPermissionGroup(
    ES_COORDINATOR_GROUP_ID
  )

  const esSitesCountQuery = useEnergyStarSitesCountQuery()
  const onboardingDataQuery = useOnboardingAccountDataQuery()
  const submissionDataQuery = useSubmissionDataQuery()

  const navigate = useNavigate()

  const availableResourceTypes = useMemo(() => {
    const uniqueResourceNamesSet = new Set(
      submissionDataQuery.data?.mappedData.map(
        ({ resourceName }) => resourceName?.toLowerCase()
      )
    )
    const uniqueResourceNamesArray = Array.from(uniqueResourceNamesSet)
    return uniqueResourceNamesArray
  }, [submissionDataQuery.data?.mappedData])

  useEffect(() => {
    if (!esSitesCountQuery.isPending && !esSitesCountQuery.data?.isEnabled)
      navigate('/')
  }, [esSitesCountQuery.data?.isEnabled, esSitesCountQuery.isPending, navigate])

  useEffect(() => {
    if (Object.keys(serializeFormQuery).length === 0) {
      navigate(
        `${SUSTAINABILITY_REPORTING_PATH}?${getUrlSearchParams(
          { ...serializeFormQuery },
          TSSiteTabs.PORTFOLIO_CONSUMPTION,
          sites,
          false
        )}`
      )
    }
  }, [navigate, serializeFormQuery, sites])

  const DropdownFilter = useCallback(
    ({ column: { filterValue, setFilter } }) => {
      const resourceTypes = Object.entries(resourceNames)
        .filter(([key]) =>
          availableResourceTypes.includes(
            key?.toLowerCase() as ResourceNameEnum
          )
        )
        .map(([key, value]) => ({
          name: value,
          id: key,
        }))

      const selectedItem = resourceTypes.find(
        ({ id }) => id === filterValue?.value
      )

      return (
        <ListSelectorStyled
          unsettable={false}
          searchable={false}
          selectedItem={selectedItem || ALL_VALUE}
          items={[ALL_VALUE, ...resourceTypes]}
          updateValue={setFilter}
          gainsightTagId='sustainability-table-resource-filter'
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
        />
      )
    },
    [availableResourceTypes, resourceNames]
  )

  const resourceTypeFilterFunction = useCallback((rows, rowId, filterValue) => {
    if (filterValue?.label === ALL_VALUE.name) return rows
    return rows.filter(
      (row) =>
        row.original.resourceName?.toLowerCase() ===
        filterValue?.value?.toLowerCase()
    )
  }, [])

  const renderMonthCellValue = ({
    value,
    id,
    submissionNotGenerated,
    submissionIsInvalid,
    tooltipContent,
  }) => {
    const renderValue = () => {
      if (submissionIsInvalid) return <CalenderDisabledIcon />
      if (submissionNotGenerated) return <CalendarWithTime />
      if (value) return value
      return <UilSyncExclamation size={20} color='#D42E2E99' />
    }
    return (
      <>
        <CellStyled id={id} data-tooltip-id={value}>
          {renderValue()}
        </CellStyled>
        {tooltipContent && (
          <StyledToolip
            style={{ maxWidth: '360px', textAlign: 'center' }}
            anchorSelect={`#${id}`}
            content={tooltipContent}
            clickable
          />
        )}
      </>
    )
  }

  const getColumns = useMemo(() => {
    const customSort = (rowA, rowB, columnId) => {
      const nameA =
        rowA.original[columnId].originalValue ?? Number.MIN_SAFE_INTEGER
      const nameB =
        rowB.original[columnId].originalValue ?? Number.MIN_SAFE_INTEGER

      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    }

    const columns: any[] = [
      {
        accessor: 'siteName',
        Header: 'Site',
        gainsightTagId: 'sustainability-table-site-filter',
        Cell: ({ value }: { value: string }) => {
          return <CellStyledBold>{value}</CellStyledBold>
        },
        Filter: DefaultColumnFilter,
        filter: siteNameFilterFunction,
        locked: true,
      },
      {
        accessor: 'resourceName',
        Header: 'Resource',
        Cell: ({ value }: { value: string }) => {
          return (
            <CellStyled>
              {value} ({unit(value?.toLowerCase() as QuantityTypes)})
            </CellStyled>
          )
        },
        Filter: DropdownFilter,
        filter: resourceTypeFilterFunction,
        locked: true,
      },
    ]
    submissionDataQuery.data?.listOfMonths.forEach((month) => {
      columns.push({
        accessor: month,
        Header: month,
        Cell: ({ value }: { value: string; row: any; column: any }) => {
          return renderMonthCellValue(value as any)
        },
        Filter: <></>,
        sortType: customSort,
        className: 'align-right',
      })
    })
    return columns
  }, [
    DropdownFilter,
    resourceTypeFilterFunction,
    submissionDataQuery.data?.listOfMonths,
    unit,
  ])

  const renderNoDataBox = () => {
    return (
      <NoDataWrapper h='400px' justify='center' align='center'>
        <ESNoDataIcon />
        <p>No sites available.</p>

        {userIsEsCoordinator && (
          <p>
            You have{' '}
            <Link to='connect-properties'>
              <b>
                {onboardingDataQuery.data?.numberOfInactiveProperties ?? 0}{' '}
                properties to connect.
              </b>
            </Link>
          </p>
        )}
      </NoDataWrapper>
    )
  }

  return (
    <Stack>
      <ChampionTiles />
      {submissionDataQuery.isPending && <Spinner size='sm' centered />}
      {submissionDataQuery.isSuccess &&
        !!submissionDataQuery.data?.mappedData?.length && (
          <TableWrapper
            isMenuOpen={isMenuOpen}
            resourceLength={availableResourceTypes?.length}
          >
            <TableCardTitleStyled>
              Submitted Monthly Consumption Per Site
            </TableCardTitleStyled>
            <RedaptiveReactTable7
              data={submissionDataQuery.data?.mappedData}
              columns={getColumns ?? []}
              showTableScrollArrows={true}
              autoResetExpanded={false}
              globalFilterable={false}
              defaultSort={[{ id: 'site' }]}
              enablePagination
              filterable
            />
          </TableWrapper>
        )}
      {submissionDataQuery.isSuccess &&
        !submissionDataQuery.data?.mappedData?.length &&
        renderNoDataBox()}
    </Stack>
  )
}

export default ESGReporting
