import axios from 'axios'
import moment from 'moment'

import {
  TSEnableESCustomerPayload,
  TSEnergyStarSubmissionDataPayload,
} from './types'
import { defaultHeaders, gcApiBaseUrl } from '../../api'
import { handleAxiosError } from '../../api/utils'
import {
  benchmarkingMockData,
  customerStatusMockData,
} from '../../mockData/energyStar'
import { isVariantActive } from '../../utils/variants'

const axiosControllers: Record<string, AbortController> = {}

export const energyStarApis = {
  fetchEnabledSitesCount: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerStatusMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }
    const controller = cancelAndCreateNewController('fetchCustomerStatus')
    const url = `${gcApiBaseUrl()}/energy-star/customers/${customerId}/sites/enabled-sites`
    return axios
      .get(url, { headers: defaultHeaders(), signal: controller.signal })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchOnboardingConnectAccount: (params) => {
    const controller = cancelAndCreateNewController('onboardingConnectAccount')
    const url = `${gcApiBaseUrl()}/energy-star/account`
    return axios
      .get(url, {
        headers: defaultHeaders(),
        params,
        signal: controller.signal,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchBenchmarkingData: (customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(benchmarkingMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }
    const controller = cancelAndCreateNewController('fetchBenchmarkingData')
    const url = `${gcApiBaseUrl()}/energy-star/benchmarking`
    const params = {
      rdpCustomerId: customerId,
    }
    return axios
      .get(url, {
        headers: defaultHeaders(),
        params,
        signal: controller.signal,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchSummaryData: (customerId) => {
    const controller = cancelAndCreateNewController('fetchSummaryData')
    const url = `${gcApiBaseUrl()}/energy-star/submissions/summary/monthly-summary`
    const params = {
      yearMonth: moment().subtract(1, 'month').format('YYYY-MM'),
      customerId,
      origin: 'r-one',
    }
    return axios
      .get(url, {
        headers: defaultHeaders(),
        params,
        signal: controller.signal,
      })
      .then(({ data }) => data?.[0])
      .catch(handleAxiosError)
  },
  fetchSubmissionsData: ({
    startYearMonth,
    endYearMonth,
    customerId,
  }: TSEnergyStarSubmissionDataPayload) => {
    const controller = cancelAndCreateNewController('fetchSubmissionsData')
    const url = `${gcApiBaseUrl()}/energy-star/submissions`
    return axios
      .get(url, {
        headers: defaultHeaders(),
        params: { startYearMonth, endYearMonth, customerId },
        signal: controller.signal,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  updateESCustomer: ({ customerId, reqData }: TSEnableESCustomerPayload) => {
    const url = `${gcApiBaseUrl()}/energy-star/customers/${customerId}`
    return axios
      .post(url, reqData, { headers: defaultHeaders() })
      .then(({ data }) => data)
      .catch((e) => {
        if (e?.response?.data?.statusCode) {
          throw e.response.data
        }
        handleAxiosError(e)
      })
  },
  fetchPendingProperties: (accountId) => {
    const controller = cancelAndCreateNewController('fetchPendingProperties')
    const url = `${gcApiBaseUrl()}/energy-star/property/available`
    return axios
      .get(url, {
        headers: defaultHeaders(),
        params: { accountId },
        signal: controller.signal,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchESPMMeterMapping: (customerId) => {
    const controller = cancelAndCreateNewController('getESPMMeterMapping')
    const url = `${gcApiBaseUrl()}/energy-star/sites/espm-meters`
    return axios
      .get(url, {
        headers: defaultHeaders(),
        params: { customerId, pending: true },
        signal: controller.signal,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  attachProperties: (reqData) => {
    const controller = cancelAndCreateNewController('attachProperties')
    const url = `${gcApiBaseUrl()}/energy-star/meters/espm-meters`
    return axios
      .post(url, reqData, {
        headers: defaultHeaders(),
        signal: controller.signal,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
}

const cancelAndCreateNewController = (methodName: string) => {
  if (axiosControllers[methodName]) {
    axiosControllers[methodName].abort()
    delete axiosControllers[methodName]
  }
  const controller = new AbortController()
  axiosControllers[methodName] = controller
  return axiosControllers[methodName]
}
