import { faker } from '@faker-js/faker'
import moment from 'moment'

import { formatNumberWithCustomFormatting } from '../../../utils'
import { siteIdMask } from '../../../utils/demoSiteIdMask'
import {
  ResourceNameEnum,
  TSBenchmarkingDataMapped,
  TSEnergyStarCustomerStateMapped,
  TSEnergyStarLastMonthData,
  TSEnergyStarSummaryDataMapped,
} from '../types'

export const demoUserBenchmarkingMapper = (
  mappedData: TSBenchmarkingDataMapped[],
  unitFormatPreference: string
): {
  mappedData: TSBenchmarkingDataMapped[]
} => {
  const checkValueAndFormat = (value: number) => {
    if (value) {
      return formatNumberWithCustomFormatting(value, unitFormatPreference)
    }
    return value
  }

  const demoMaskedSites = Object.values(siteIdMask)
    .filter(({ isWpt }) => isWpt)
    .sort((b, a) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })

  const newMappedData = mappedData
    .slice(0, demoMaskedSites.length)
    .map((item, i) => {
      faker.seed(i)
      const siteEui = faker.number.float({ min: 10, max: 100, precision: 0.1 })
      const sourceEui = faker.number.float({
        min: 10,
        max: 100,
        precision: 0.1,
      })
      const nationalMedianSiteEui = faker.number.float({
        min: 20,
        max: 50,
        precision: 0.1,
      })
      const nationalMedianSourceEui = faker.number.float({
        min: 40,
        max: 80,
        precision: 0.1,
      })
      const nationalMedianSiteEuiDiff = parseFloat(
        (nationalMedianSiteEui - siteEui).toFixed(2)
      )
      const nationalMedianSourceEuiDiff = parseFloat(
        (nationalMedianSourceEui - sourceEui).toFixed(2)
      )
      const energyStarScore = faker.number.int({ min: 50, max: 100 })
      const nationalMedianEnergyStarSource = faker.number.int({
        min: 40,
        max: 80,
      })
      const eligibleForCertification = faker.helpers.arrayElement(['Yes', 'No'])
      const totalGhgEmissions = faker.number.float({
        min: 100,
        max: 500,
        precision: 0.1,
      })

      return {
        ...item,
        siteName: demoMaskedSites[i].name,
        siteEui: checkValueAndFormat(siteEui),
        sourceEui: checkValueAndFormat(sourceEui),
        nationalMedianSiteEui: checkValueAndFormat(nationalMedianSiteEui),
        nationalMedianSourceEui: checkValueAndFormat(nationalMedianSourceEui),
        nationalMedianSiteEuiDiff: checkValueAndFormat(
          nationalMedianSiteEuiDiff
        ),
        nationalMedianSourceEuiDiff: checkValueAndFormat(
          nationalMedianSourceEuiDiff
        ),
        energyStarScore: checkValueAndFormat(energyStarScore),
        nationalMedianEnergyStarSource: checkValueAndFormat(
          nationalMedianEnergyStarSource
        ),
        totalGhgEmissions: checkValueAndFormat(totalGhgEmissions),
        eligibleForCertification,
      }
    })

  return {
    mappedData: newMappedData,
  }
}

export const demoUserSubmissionsMapper = (
  data: TSEnergyStarSummaryDataMapped[],
  listOfMonths: string[],
  unitFormatPreference: string
): TSEnergyStarSummaryDataMapped[] => {
  const demoMaskedSites = Object.values(siteIdMask)
    .filter(({ isWpt }) => isWpt)
    .sort((b, a) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })

  return data.slice(0, demoMaskedSites.length).map((item, i) => {
    faker.seed(i)
    return {
      ...item,
      siteId: item.siteId,
      siteName: demoMaskedSites[i].name,
      resourceName: faker.helpers.arrayElement(Object.values(ResourceNameEnum)),
      ...listOfMonths.reduce((acc, cur, j) => {
        const submissionMonth = moment(cur, 'MMM YYYY').add(1, 'M').format('MM')
        if (demoMaskedSites.length - i === 2 && j === 1) {
          return {
            ...acc,
            [cur]: {
              originalValue: null,
              value: null,
              date: '03/28/2024',
              meterList: [],
              id: faker.string.alpha(10),
              tooltipContent:
                'Consumption details were not sent to ENERGY STAR. Check with Redaptive Customer Representative for details.',
            },
          }
        }
        const originalValue = Number(
          faker.number.int({ min: 10000, max: 100000 })
        )
        return {
          ...acc,
          [cur]: {
            originalValue,
            value: formatNumberWithCustomFormatting(
              originalValue,
              unitFormatPreference
            ),
            date: `${submissionMonth}/10/2024`,
            meterList: [],
            id: faker.string.alpha(10),
            tooltipContent: `This value was submitted to ENERGY STAR on ${submissionMonth}/10/2024`,
          },
        }
      }, {}),
    }
  })
}

export const demoUserMonthlySummaryPayload = (
  apiData: TSEnergyStarLastMonthData
): TSEnergyStarLastMonthData => {
  return {
    ...apiData,
    enabledSites: 18,
    sitesSubmittedForElectricity: 10,
    totalSitesForElectricity: 13,
    sitesSubmittedForWater: 10,
    totalSitesForWater: 11,
    sitesSubmittedForGas: 8,
    totalSitesForGas: 10,
    sitesNotSubmittedSuccessfully: 14,
  }
}

export const demoUserCustomerMappedPayload =
  (): TSEnergyStarCustomerStateMapped => {
    return {
      isEnabled: true,
      sitesCount: {
        electric: 13,
        water: 11,
        gas: 10,
      },
      energyStarCustomerId: 1234,
    }
  }
