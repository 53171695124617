import {
  UilAngleDown,
  UilAngleUp,
  UilChartLine,
  UilSort,
} from '@iconscout/react-unicons'
import DoubleDownIcon from '@iconscout/react-unicons/icons/uil-angle-double-down'
import DoubleUpIcon from '@iconscout/react-unicons/icons/uil-angle-double-up'
import {
  Card,
  Container,
  Grid,
  MantineStyleProps,
  Pill,
  Stack,
} from '@mantine/core'
import { max, min } from 'lodash'
import moment from 'moment'
import makeNatSort from 'natsort'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import styles from './styles.module.css'
import ConsumptionAnalysisChart from '../../components/CircuitsUsageDataView/ConsumptionAnalysisSingleSiteLineChart'
import Button from '../../components/mantine/Button'
import Pagination from '../../components/mantine/Pagination'
import Quantity from '../../components/mantine/Quantity'
import { ImperialUnits } from '../../components/mantine/Quantity/types'
import {
  getFirstVisibleRow,
  getLastVisibleRow,
} from '../../components/SmartPaginator'
import { DATE_FORMAT_DATA_API_RESPONSE_NO_TZ } from '../../constants'
import { TSSiteResponse } from '../../ducks/sites'
import { Resolution } from '../../ducks/types'
import { EnhancedGroupData } from '../../queries/resourceUsage'

const cardProps = {
  shadow: '0',
  padding: '0',
  radius: 'md',
  withBorder: true,
}

const headerColProps = {
  lh: '1',
  ta: 'right',
} as Partial<MantineStyleProps>

const primaryHeaderColProps = {
  ta: 'left',
} as Partial<MantineStyleProps>

const dataColProps = {
  lh: '1',
  ta: 'right',
} as Partial<MantineStyleProps>

const columns = [
  {
    title: 'Groups',
    accessor: 'groupName',
    sorted: '',
  },
  {
    title: 'Average',
    accessor: 'average',
    sorted: '',
  },
  {
    title: 'Minimum',
    accessor: 'min',
    sorted: '',
  },
  {
    title: 'Maximum',
    accessor: 'max',
    sorted: '',
  },
  {
    title: 'Total',
    accessor: 'totalUsage',
    sorted: 'desc',
  },
]

const updateSorting = (currentSort) => {
  if (currentSort === 'asc') {
    return 'desc'
  } else if (currentSort === 'desc') {
    return ''
  } else {
    return 'asc'
  }
}

type Props = {
  fromDate?: string
  groups?: EnhancedGroupData[]
  resolution: Resolution
  singleSite?: TSSiteResponse
  toDate?: string
  ts?: string[]
  units?: ImperialUnits[]
}

const ConsumptionList = (props: Props) => {
  const {
    fromDate,
    groups = [],
    resolution,
    singleSite,
    toDate,
    ts = [],
    units = [],
  } = props
  const theme = useTheme()
  const timezone = singleSite?.address?.timezone || 'UTC'

  const data = groups.map((group) => {
    return {
      ...group,
      average: group.totalUsage / ts.length,
      min: min(group.usage) ?? 0,
      max: max(group.usage) ?? 0,
      expanded: false,
    }
  })

  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 0,
    rowsPerPage: 10,
    totalRows: groups.length,
  })

  const [columnsSetting, updateColumnsSetting] = useState(columns)
  const [tableData, updateTableData] = useState(data)

  const sortedData = useMemo(() => {
    const columnToSort = columnsSetting.filter(({ sorted }) => sorted)[0]
    const sorter = makeNatSort({
      insensitive: true,
      desc: columnToSort?.sorted === 'desc',
    })

    if (columnToSort) {
      return [...tableData].sort((a, b) =>
        sorter(a[columnToSort?.accessor], b[columnToSort?.accessor])
      )
    }
    return [...tableData]
  }, [tableData, columnsSetting])

  const isRowVisible = (ix: number) => {
    const startIndex = getFirstVisibleRow(paginationSettings)
    const endIndex = getLastVisibleRow(paginationSettings)
    return ix >= startIndex && ix <= endIndex
  }

  const isAllExpanded = useMemo(
    () => tableData.every((val) => val.expanded),
    [tableData]
  )

  const handleSortingUpdate = (value: string, columnAccessor: string) => {
    const updatedColumnsSetting = columnsSetting.map((column) => {
      return {
        ...column,
        sorted: column.accessor === columnAccessor ? value : '',
      }
    })
    updateColumnsSetting(updatedColumnsSetting)
  }

  const handleExpandCollapseOne = (groupId: string) => {
    const newValues = tableData.map((group) => {
      const newExpand =
        group.groupId == groupId
          ? !group.expanded // toggle the current value
          : group.expanded
      return {
        ...group,
        expanded: newExpand,
      }
    })
    updateTableData(newValues)
  }

  const handleExpandCollapseAll = (expand: boolean) => {
    const newValues = tableData.map((group) => ({
      ...group,
      expanded: expand,
    }))
    updateTableData(newValues)
  }

  return (
    <Stack gap='sm'>
      <Card {...cardProps} bg='gray.1' mb='0.5rem'>
        <Card.Section>
          <Grid columns={12} p='sm' align='center' gutter={0}>
            {columnsSetting.map(({ title, sorted, accessor }, indx) => {
              const columnProps =
                indx === 0
                  ? { ...headerColProps, ...primaryHeaderColProps }
                  : { ...headerColProps }

              return (
                <Grid.Col
                  aria-label='Header'
                  key={title}
                  span={2}
                  {...columnProps}
                >
                  <Button
                    variant='transparent'
                    bg='gray.1'
                    fz='md'
                    p='0'
                    c='black'
                    fw='400'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleSortingUpdate(updateSorting(sorted), accessor)
                    }
                  >
                    <span>{title}</span>
                    {sorted === 'asc' && (
                      <UilAngleUp color='#6C6D6F' size='16' />
                    )}
                    {sorted === 'desc' && (
                      <UilAngleDown color='#6C6D6F' size='16' />
                    )}
                    {!sorted && <UilSort color='#6C6D6F' size='16' />}
                  </Button>
                </Grid.Col>
              )
            })}
            <Grid.Col span={2} {...headerColProps} lh=''>
              <Container ta='right' p='0'>
                {isAllExpanded ? (
                  <Button
                    variant='outline'
                    color='gray.6'
                    size='sm'
                    h={32}
                    data-gainsight-id='consumption-analysis-collapse-all-button'
                    onClick={() => handleExpandCollapseAll(false)}
                  >
                    Collapse All
                    <DoubleUpIcon height={20} width={20} />
                  </Button>
                ) : (
                  <Button
                    variant='outline'
                    color='gray.6'
                    size='sm'
                    h={32}
                    data-gainsight-id='consumption-analysis-expand-all-button'
                    onClick={() => handleExpandCollapseAll(true)}
                  >
                    Expand All
                    <DoubleDownIcon height={20} width={20} />
                  </Button>
                )}
              </Container>
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Card>
      {/* Repeat for each analysis group: */}
      {sortedData.map((row, ix) => {
        if (!isRowVisible(ix)) {
          return null
        }
        const {
          expanded,
          groupName,
          average,
          min,
          max,
          totalUsage,
          groupId,
          usage,
        } = row
        return (
          <Card
            aria-label='Row'
            key={`row_analysis_${ix}`}
            {...cardProps}
            className={styles.card}
            data-expanded={expanded}
            data-gainsight-id='consumption-analysis-button-expand-collapse-all'
          >
            <Card.Section onClick={() => handleExpandCollapseOne(groupId)}>
              <Grid columns={12} p='sm' align='center' gutter={0}>
                <Grid.Col {...dataColProps} ta='left' span={2} fw='600'>
                  {groupName}
                </Grid.Col>
                <Grid.Col {...dataColProps} span={2}>
                  <Quantity
                    value={average}
                    quantityType={units[0]}
                    precision={0}
                  />
                </Grid.Col>
                <Grid.Col {...dataColProps} span={2}>
                  <Quantity value={min} quantityType={units[0]} precision={0} />
                </Grid.Col>
                <Grid.Col {...dataColProps} span={2}>
                  <Quantity value={max} quantityType={units[0]} precision={0} />
                </Grid.Col>
                <Grid.Col {...dataColProps} span={2}>
                  <Quantity
                    value={totalUsage}
                    quantityType={units[0]}
                    precision={0}
                  />
                </Grid.Col>
                <Grid.Col {...dataColProps} pt={2} span={2} ta='right'>
                  <Pill
                    radius='xl'
                    bg={expanded ? 'blue.5' : 'blue.0'}
                    h='32'
                    pt='7'
                    data-gainsight-id='consumption-analysis-button-expand-collapse-all'
                  >
                    <UilChartLine
                      height={14}
                      width={14}
                      color={expanded ? 'white' : theme.colors.primary100}
                    />
                  </Pill>
                </Grid.Col>
              </Grid>
            </Card.Section>
            {expanded && (
              <Card.Section
                bg='#fff'
                p='sm'
                data-gainsight-id='consumption-analysis-chart'
              >
                <ConsumptionAnalysisChart
                  startDate={moment(
                    fromDate,
                    DATE_FORMAT_DATA_API_RESPONSE_NO_TZ
                  )}
                  endDate={moment(toDate, DATE_FORMAT_DATA_API_RESPONSE_NO_TZ)}
                  resolution={resolution}
                  ts={ts}
                  usageData={usage ?? []}
                  chartRef={null}
                  timezone={timezone}
                  unit={units[0]}
                />
              </Card.Section>
            )}
          </Card>
        )
      })}
      {/* end of if not loading */}
      <Pagination
        total={paginationSettings.totalRows / paginationSettings.rowsPerPage}
        totalItems={paginationSettings.totalRows}
        itemsPerPage={paginationSettings.rowsPerPage}
        currentPage={paginationSettings.currentPage}
        onChangePage={(page) => {
          setPaginationSettings({
            ...paginationSettings,
            currentPage: page - 1,
          })
        }}
        onPageSizeChange={(size: number) =>
          setPaginationSettings((prevState) => ({
            ...prevState,
            rowsPerPage: size,
          }))
        }
      />
    </Stack>
  )
}

export default ConsumptionList
