import { notifications } from '@mantine/notifications'
import { floor, uniq } from 'lodash'

import styles from './notifications.module.css'
import { titleCase } from '../../../utils'
import MessageBatcher from '../../../utils/MessageBatcher'

const removeIds = (filter) => filter.replace(/[\s_]ids/gi, 's')

type ChangedFilters = string[]
const changedFiltersNotification = (changedFilters: ChangedFilters) => {
  const message = uniq(changedFilters)
    .map((filter) => titleCase(removeIds(filter)))
    .join(', ')
  const duration = floor(message.length / 52) * 2000 + 6000 // 6s for one line of text and +2s for each additional line
  notifications.show({
    title: 'Some filters were invalid and have been updated',
    message: `Changed filters: ${message}`,
    className: styles.notification,
    autoClose: duration,
  })
}

const filterNotificationsBatcher = new MessageBatcher<string>(
  changedFiltersNotification,
  250
)

export default filterNotificationsBatcher
