import moment from 'moment'

import { allProjectApis } from './apis'
import {
  projectColumnMap,
  projectStageMap,
  TSCreateCommentPayload,
  TSFiltersState,
  TSLabels,
  TSOpportunityBase,
  TSOpportunityBatch,
  TSOpportunityBatchResponse,
  TSOpportunityComment,
  TSOpportunityEntityType,
  TSOpportunitySeed,
  TSOpportunitySeedResponse,
  TSProjectColumn,
} from './types'
import {
  DATE_FORMAT_DATA_API_RESPONSE,
  DATE_RANGE_PICKER_FORMAT,
} from '../../constants'
import { EMPTY_METRIC_STRING } from '../../constants/strings'
import {
  findOpportunitiesMaxValue,
  findOpportunitiesMinValue,
  naturallySort,
} from '../../utils'

export const entityById = (entity) => {
  return {
    ...entity.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  }
}
export const enhanceProject = (
  seed: TSOpportunitySeedResponse
): TSOpportunitySeed => ({
  ...seed,
  entityType: TSOpportunityEntityType.SEED,
})
export const enhanceBatch = (
  batch: TSOpportunityBatchResponse
): TSOpportunityBatch => ({
  ...batch,
  entityType: TSOpportunityEntityType.BATCH,
})
export const enhanceComment = (
  comment: TSOpportunityComment
): TSOpportunityComment => {
  // TODO: make separate const formats for these?
  return {
    ...comment,
    created: moment(comment.created, DATE_FORMAT_DATA_API_RESPONSE).format(
      DATE_RANGE_PICKER_FORMAT
    ),
  }
}

export const handleCreateProjectComment = async (
  payload: TSCreateCommentPayload
) => {
  let commentId: string | null = null

  if (payload.comment !== '') {
    const comment = await allProjectApis.createProjectComment(payload)
    commentId = comment.id
  }

  if (payload.acceptedFiles && payload.acceptedFiles.length > 0) {
    const preSignedUrls = await Promise.all(
      payload.acceptedFiles.map((file) =>
        allProjectApis.generatePreSignedUrls(file.name, payload.opportunityId)
      )
    )

    await Promise.all(
      payload.acceptedFiles.map((file, index) => {
        return allProjectApis.uploadFileUsingPreSignedUrls(
          preSignedUrls[index].preSignedUrlToUpload,
          file
        )
      })
    )
    await Promise.all(
      preSignedUrls.map((urls, index) =>
        allProjectApis.addProjectAttachmentMetadata(
          payload.opportunityId,
          urls.preSignedUrlToDownload,
          commentId!,
          payload.acceptedFiles[index].name
        )
      )
    )
  }

  return { commentId }
}

export const handleCreateBatchComment = async (
  payload: TSCreateCommentPayload
) => {
  let commentId: string | null = null

  if (payload.comment !== '') {
    const comment = await allProjectApis.createBatchComment(payload)
    commentId = comment.id
  }

  if (payload.acceptedFiles && payload.acceptedFiles.length > 0) {
    const preSignedUrls = await Promise.all(
      payload.acceptedFiles.map((file) =>
        allProjectApis.generatePreSignedUrls(file.name, payload.opportunityId)
      )
    )

    await Promise.all(
      payload.acceptedFiles.map((file, index) => {
        return allProjectApis.uploadFileUsingPreSignedUrls(
          preSignedUrls[index].preSignedUrlToUpload,
          file
        )
      })
    )

    await Promise.all(
      preSignedUrls.map((urls, index) =>
        allProjectApis.addBatchAttachmentMetadata(
          payload.opportunityId,
          urls.preSignedUrlToDownload,
          commentId!,
          payload.acceptedFiles[index].name
        )
      )
    )
  }

  return { commentId }
}

export const getFirstStageForColumn = (column: TSProjectColumn) => {
  return projectColumnMap[column].stages[0]
}

// TODO: currently always grouping, let's confirm we don't need these ungrouped and remove the option
export const getProjectStageValuesForSelect = (dontGroup = false) => {
  const projectStageValues = [] as any
  if (!dontGroup) {
    for (const key in projectColumnMap) {
      const items = [] as any
      projectColumnMap[key].stages.forEach((stage) => {
        items.push({
          label: projectStageMap[stage].label,
          value: stage,
        })
      })

      projectStageValues.push({
        group: projectColumnMap[key].label,
        items,
      })
    }
  } else {
    for (const key in projectStageMap) {
      projectStageValues.push({
        label: projectStageMap[key].label,
        name: projectStageMap[key].label, // needed for filter, still uses ListSelector
        value: key,
        id: key,
      })
    }
  }
  return projectStageValues
}

export const getLabelOptionsForTitle = (title: string, labels: TSLabels) => {
  const options = labels
    .filter((label) => label.title == title)
    .map((label) => ({
      // this works for mantine + react-select format
      value: label.id,
      label: label.text,
    }))
  // sort alphabetically
  options.sort((a, b) => naturallySort(a.label, b.label))
  if (title === 'Estimate Precision') {
    options.reverse() // the right order happens to be reverse alphabetical
  }
  return options
}

export const getLabelOptionsByGroupForReactSelect = (labels: TSLabels) => {
  return [
    {
      // react-select format:
      label: 'Estimate Precision',
      options: getLabelOptionsForTitle('Estimate Precision', labels),
    },
    { label: 'Other', options: getLabelOptionsForTitle('Other', labels) },
  ]
}

export const getLabelOptionsByGroupForMantine = (labels: TSLabels) => {
  return [
    {
      // mantine format:
      group: 'Estimate Precision',
      items: getLabelOptionsForTitle('Estimate Precision', labels),
    },
    { group: 'Other', items: getLabelOptionsForTitle('Other', labels) },
  ]
}

export const ecmTypeToMlovMapping = {
  // use smallcase without spaces and hyphens to match format
  solar: 'SOLAR_ECM_SPECIFIC_FIELDS',
  lighting: 'LIGHTING_ECM_SPECIFIC_FIELDS',
  hvac: 'HVAC_ECM_SPECIFIC_FIELDS',
  lightingcontrols: 'LIGHTING_CONTROL_ECM_SPECIFIC_FIELDS',
  hvaccontrols: 'HVAC_CONTROL_ECM_SPECIFIC_FIELDS',
  bmsaas: 'BMSAAS_CONTROL_ECM_SPECIFIC_FIELDS',
}
const initialFilterState: TSFiltersState = {
  selectedAddresses: { values: [], isActive: false },
  selectedEcmTypes: { values: [], isActive: false },
  selectedProjectStages: { values: [], isActive: false },
  selectedLabels: { values: [], isActive: false },
  costSavings: { values: [0, 10000000], isActive: false },
  co2Savings: { values: [0, 10000000], isActive: false },
  simplePaybackPeriod: { values: [0, 10000000], isActive: false },
  bookmarkedOnly: false,
  selectedOnly: false,
  searchKey: '',
}

export const getDefaultFiltersWithOpportunityLimits = (
  opportunitySeeds: Array<TSOpportunitySeed>
): TSFiltersState => {
  return {
    ...initialFilterState,
    costSavings: {
      values: [
        findOpportunitiesMinValue(
          opportunitySeeds,
          'annualNetCostSavings',
          true
        ),
        findOpportunitiesMaxValue(
          opportunitySeeds,
          'annualNetCostSavings',
          true
        ),
      ],
      isActive: false,
    },
    co2Savings: {
      values: [
        findOpportunitiesMinValue(opportunitySeeds, 'annualAvoidedCo2e', true),
        findOpportunitiesMaxValue(opportunitySeeds, 'annualAvoidedCo2e', true),
      ],
      isActive: false,
    },

    simplePaybackPeriod: {
      values: [
        findOpportunitiesMinValue(
          opportunitySeeds,
          'simplePaybackPeriod',
          true
        ),
        findOpportunitiesMaxValue(
          opportunitySeeds,
          'simplePaybackPeriod',
          true
        ),
      ],
      isActive: false,
    },
  }
}

export const getSelectedSeeds = (
  seeds: Array<TSOpportunityBase>,
  filters: TSFiltersState,
  labels: TSLabels,
  selectedSeeds: Record<string, boolean>
): Array<TSOpportunityBase> => {
  return getSeedsThatMatchFilter(
    seeds,
    { ...filters, selectedOnly: true },
    labels,
    selectedSeeds
  )
}

export const getSeedsThatMatchFilter = (
  opportunities: Array<TSOpportunityBase>,
  filters: TSFiltersState,
  labels: TSLabels,
  selectedSeeds: Record<string, boolean>
): Array<TSOpportunityBase> => {
  return opportunities.filter((opportunity) => {
    // This function runs over each individual opportunity to check filter criteria
    const shouldBeReturned = (opportunity: TSOpportunityBase) => {
      const {
        ecmType,
        projectStage,
        bookmark,
        id,
        entityType,
        displayId,
        labelIds,
        title,
      } = opportunity
      //Filer bookmarked items
      if (filters.bookmarkedOnly && !bookmark) {
        return false
      }
      //Filer selected items
      if (filters.selectedOnly && !selectedSeeds[id]) {
        return false
      }

      // keyword
      const matchingSearchLabelIds = labels
        ?.filter((label) =>
          label.text.toLowerCase().includes(filters.searchKey.toLowerCase())
        )
        .map((label) => label.id)

      // Filter by selected ECM type
      if (
        filters.selectedEcmTypes.values &&
        filters.selectedEcmTypes.values.length > 0 &&
        !filters.selectedEcmTypes.values.includes(ecmType)
      ) {
        return false
      }

      // Filter by project stage
      if (
        filters.selectedProjectStages.values &&
        filters.selectedProjectStages.values.length > 0 &&
        !filters.selectedProjectStages.values.includes(projectStage)
      ) {
        return false
      }

      // Filter by selected label
      if (
        filters.selectedLabels.values &&
        filters.selectedLabels.values.length > 0
      ) {
        const seedLabels = labels.filter((label) => labelIds.includes(label.id))
        const hasMatchingLabel = !!seedLabels.find((seedLabel) =>
          filters.selectedLabels.values.includes(seedLabel.id)
        )
        if (!hasMatchingLabel) return false
      }

      // seed-specific filter logic
      if (entityType == 'seed') {
        const { address1, city, state } = opportunity as TSOpportunitySeed

        // Filter by selected address
        if (
          filters.selectedAddresses.values &&
          filters.selectedAddresses.values.length > 0 &&
          !filters.selectedAddresses.values.includes(address1)
        ) {
          return false
        }

        // keyword
        if (
          filters.searchKey &&
          !address1.toLowerCase().includes(filters.searchKey) &&
          !title?.toLowerCase().includes(filters.searchKey) &&
          !ecmType?.toLowerCase().includes(filters.searchKey) &&
          !displayId.toLowerCase().includes(filters.searchKey) &&
          !city.toLowerCase().includes(filters.searchKey) &&
          !state.toLowerCase().includes(filters.searchKey) &&
          (matchingSearchLabelIds.length <= 0 ||
            !labelIds.some((label) => matchingSearchLabelIds.includes(label)))
        ) {
          return false
        }
      }
      // end seed-specific filter logic

      // batch-specific filter logic
      if (entityType == 'batch') {
        const { title } = opportunity as TSOpportunityBatch

        // Filter by selected address
        if (
          filters.selectedAddresses &&
          filters.selectedAddresses.values.length > 0
        ) {
          return false
        }

        if (
          filters.searchKey &&
          !title?.toLowerCase().includes(filters.searchKey) &&
          !ecmType?.toLowerCase().includes(filters.searchKey) &&
          !displayId?.toLowerCase().includes(filters.searchKey) &&
          (matchingSearchLabelIds.length <= 0 ||
            !labelIds.some((label) => matchingSearchLabelIds.includes(label)))
        ) {
          return false
        }
      }
      // end batch-specific filter logic

      return true // Include item in the filtered array
    }
    // if the current seed is not a batch, check for filter criteria
    if (opportunity.entityType == 'seed') {
      return shouldBeReturned(opportunity)
    } else if (opportunity.entityType == 'batch') {
      // if the current seed is a batch, check for filter criteria and check each child for filter criteria
      // if the batch or even one child fulfils the criteria, we return the enire batch
      if (shouldBeReturned(opportunity)) {
        return true
      } else {
        if ((opportunity as TSOpportunityBatch).subRows?.length) {
          const matchingChildren = (
            opportunity as TSOpportunityBatch
          ).subRows?.filter((seed) => shouldBeReturned(seed))
          return matchingChildren?.length != 0
        }
      }
    }
  })
}

export const getDisplayAddress = (
  opportunity: TSOpportunityBase,
  addressType: 'short' | 'full' = 'short'
) => {
  return opportunity.entityType == 'seed'
    ? getCombinedOpportunityAddress(
        opportunity as TSOpportunitySeed,
        addressType
      )
    : getCombinedBatchAddress(opportunity as TSOpportunityBatch)
}

export const getCombinedOpportunityAddress = (
  seed: TSOpportunitySeed,
  addressType: 'short' | 'full' = 'short'
) => {
  const { address1, city, state, postalcode, country } = seed
  const addressParts =
    addressType == 'full'
      ? [address1, city, state, postalcode, country]
      : [city, state]
  const combinedAddress = addressParts
    .filter((part) => part !== undefined && part !== '')
    .join(', ')
  return combinedAddress
}

const getCombinedBatchAddress = (batch: TSOpportunityBatch) => {
  const { subRows } = batch

  if (subRows?.length) {
    const firstItem = subRows[0]
    const areAddressesSame = subRows.every(
      (item) =>
        item.address1 === firstItem.address1 &&
        item.address2 === firstItem.address2 &&
        item.state === firstItem.state &&
        item.city === firstItem.city
    )
    if (areAddressesSame) {
      // Return the values from child
      return getCombinedOpportunityAddress(firstItem)
    } else {
      return 'Multiple Addresses'
    }
  } else {
    return EMPTY_METRIC_STRING
  }
}

export const combineSeedsAndBatches = (
  seeds?: Array<TSOpportunitySeed>,
  batches?: Array<TSOpportunityBatch>
): ({ displayAddress: string } & TSOpportunityBase)[] => {
  const result: ({ displayAddress: string } & TSOpportunityBase)[] = []

  if (batches?.length) {
    batches.forEach((batch: any) => {
      const matchingSeeds = seeds
        ?.filter((seed) => batch?.opportunityIds?.includes(seed.id))
        .map((childSeed) => {
          return {
            ...childSeed,
            displayAddress: getCombinedOpportunityAddress(childSeed),
            parent: {
              id: batch.id,
              title: batch.title,
              displayId: batch.displayId,
            },
          }
        })
      if (matchingSeeds && matchingSeeds.length > 0) {
        batch = {
          ...batch,
          subRows: matchingSeeds,
        }
        seeds = seeds?.filter((seed) => !batch.opportunityIds.includes(seed.id))
      }
      result.push({ ...batch, displayAddress: getCombinedBatchAddress(batch) })
    })
  }

  if (seeds?.length) {
    seeds.forEach((seed) => {
      result.push({
        ...seed,
        displayAddress: getCombinedOpportunityAddress(seed),
      })
    })
  }
  // Sort the final output
  result.sort((a, b) => {
    if (a.entityType == 'seed') {
      return 1
    }
    if (b.entityType == 'batch') {
      return -1
    }
    return 0
  })

  return result
}

export const getParentBatchForSeed = (
  seed: TSOpportunitySeed,
  batches: Array<TSOpportunityBatch>
): TSOpportunityBatch | null => {
  const matchingBatches = batches.filter((batch) =>
    batch.opportunityIds.includes(seed.id)
  )
  if (matchingBatches.length) {
    return matchingBatches[0] // This is assuming the seed belongs to at most one batch
  }
  return null
}

export const getSeedsForBatch = (
  batch: TSOpportunityBatch,
  seeds: Array<TSOpportunitySeed>
): Array<TSOpportunitySeed> => {
  return seeds.filter((seed) => batch.opportunityIds?.includes(seed.id))
}
export const getSeedsThatDonotBelongToAnyBatch = (
  batches: Array<TSOpportunityBatch>,
  seeds: Array<TSOpportunitySeed>
): Array<TSOpportunitySeed> => {
  return seeds.filter((opp) => {
    return !batches.some((batch) => batch.opportunityIds.includes(opp.id))
  })
}
