export interface TSEnergyStarCustomerState {
  integrationEnabled: boolean
  enabledElectricSites: number
  enabledWaterSites: number
  enabledNaturalGasSites: number
  energyStarCustomerId: number
}

export interface TSOnboardingAccountInfoResponse {
  isValid: boolean
  numberOfPropertiesAssociated: null | number
  numberOfInactiveProperties: null | number
  isAlreadyVerified: boolean
}

export interface TSEnergyStarCustomerStateMapped {
  isEnabled: boolean
  energyStarCustomerId: number | null
  sitesCount: {
    electric: number
    water: number
    gas: number
  } | null
}

export interface TSEnergyStarLastMonthData {
  customerName: string
  enabledSites: number
  sitesSubmittedForElectricity: number
  totalSitesForElectricity: number
  sitesSubmittedForWater: number
  totalSitesForWater: number
  sitesSubmittedForGas: number
  totalSitesForGas: number
  sitesNotSubmittedSuccessfully: number
  latestSubmissionDate: string
}

export enum ResourceNameEnum {
  electricity = 'Electric',
  naturalGas = 'Gas',
  water = 'Water',
}
export interface TSEnergyStarSummaryDataOriginal {
  id: string
  customerId: string
  siteId: string
  energyStarMeterId?: string
  espmMeterId?: number
  reportMonthlyCycle: string
  customerName: string
  siteName: string
  resourceType: string
  meterDataVerifiedDate: string | null
  espmStartdate: string | null
  dataSource?: string
  dataAggregation: 'wholeSite' | 'meter'
  meterName: string | null
  status: 'Not Submitted' | 'In Progress' | 'Failed' | 'SUCCESS'
  dataAvailability: number
  currentMonthEnergyConsumption: number
  submissionDate: string
  previousMonthEnergyConsumption: number
  deltaEnergyConsumption: number
}

export interface TSMappedMeter {
  name: string
  value: string
  date: string
}

export interface TSMappedMonthObj {
  value: string
  date: string
  meterList: TSMappedMeter[]
}
export interface TSBenchmarkingDataOriginal {
  id: string
  created: string
  createdBy: null
  modified: string
  modifiedBy: null
  siteId: string
  siteName: string
  meterDetails: any
  yearMonth: string
  siteEui: number
  sourceEui: number
  nationalMedianSiteEui: number
  nationalMedianSourceEui: number
  nationalMedianSiteEuiDiff: number
  nationalMedianSourceEuiDiff: number
  energyStarScore: number
  nationalMedianEnergyStarSource: number
  eligibleForCertification: string
  totalGhgEmissions: number
}

export interface TSBenchmarkingDataMapped {
  id: string
  siteEui: number
  siteName: string
  modified: string
  nationalMedianSiteEui: number
  nationalMedianSiteEuiDiff: number
  energyStarScore: number
  eligibleForCertification: string
  totalGhgEmissions: number
}

export interface TSEnergyStarSummaryDataMapped {
  siteId: string | number
  siteName: string
  resourceName: ResourceNameEnum
  currentMonthEnergyConsumption: number
  reportMonthlyCycle: string
  [key: string]: string | number | TSMappedMonthObj
}

export interface TSEnergyStarDownload {
  siteName: string
  resourceName: string
  espmMeterId: string
  meterStartDate: string
  [key: string]: string | number
}

export interface TSBenchmarkingCardData {
  siteWithLowestEUI: string | null
  siteWithLowestGHGSubmissions: string | null
  sitesEligibleForCertification: number | null
}

export interface TSPendingPropertySite {
  siteName: string
  rdpSiteId: string
  esPropertyName: string
  rdpCustomerId: string
  esPropertyId: number
  esMeters: TSPropertyMeter[]
}

interface TSPropertyMeter {
  esMeterId: number | null
  esMeterType: 'electricity' | 'water' | 'naturalGas'
  esMeterEnabled: boolean
  esMeterActive: boolean
}

export interface ConnectPropertyMapped {
  siteName: string
  rdpSiteId: string
  esPropertyData: {
    value: string | null
    isDisabled: boolean
    label: string | null
  }
  electricity: {
    isDisabled: boolean
    isChecked: boolean
  }
  water: {
    isDisabled: boolean
    isChecked: boolean
  }
  naturalGas: {
    isDisabled: boolean
    isChecked: boolean
  }
}

export interface TSPendingPropertiesOriginal {
  createdBy: string
  modified: string | null
  modifiedBy: string | null
  id: string
  accountId: number
  propertyId: number
  propertyName: string
  isActive: boolean
  isAccepted: boolean
}

export interface TSPendingPropertiesMapped {
  value: string
  label: string
}

export interface TSEnergyStarSubmissionData {
  originalData: TSEnergyStarSummaryDataOriginal[]
  mappedData: TSEnergyStarSummaryDataMapped[]
  listOfMonths: string[]
  allSitesDownloadJSON: TSEnergyStarDownload[]
}

export interface TSEnergyStarSubmissionDataPayload {
  startYearMonth: string
  endYearMonth: string
  customerId: string
}

export interface TSEnableESCustomerPayload {
  customerId: string
  reqData: {
    enabled: boolean
    energyStarId: string
    sendMonthlyReport: boolean
    emailId: string
  }
}
