import BuildingIcon from '@iconscout/react-unicons/icons/uil-building'
import LayerGroupIcon from '@iconscout/react-unicons/icons/uil-layer-group'
import SlidersIcon from '@iconscout/react-unicons/icons/uil-sliders-v'
import { BadgeProps } from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component'
import { JSX } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import CollapsedNavContent from './CollapsedNavContent'
import ExpandedNavContent from './ExpandedNavContent'
import NavToggleButton from './NavToggleButton'
import { Period } from '../../constants'
import {
  ALERTS,
  ALL_PROJECTS_POST_NTP_PATH,
  CONSUMPTION_ANALYSIS_PATH,
  PORTFOLIO_CONSUMPTION_PATH,
  PORTFOLIO_SITES_PATH,
  PROJECTS_PATH,
  PROJECTS_PERFORMANCE_PATH,
  SETTINGS_PATH,
  SUSTAINABILITY_BENCHMARKING_PATH,
  SUSTAINABILITY_REPORTING_PATH,
} from '../../constants/paths'
import { MIT_GROUP_ID } from '../../constants/strings'
import { selectSitesEntity, TSSiteTabs } from '../../ducks/sites'
import { useCurrentCustomerStore } from '../../globalState/currentCustomer'
import { useFetchAllProjectsModuleCustomers } from '../../queries/allProjects'
import { useFetchAllProjectsModuleCustomers as useFetchAllProjectsPostNtpModuleCustomers } from '../../queries/allProjectsPostNtp'
import {
  useEnergyStarSitesCountQuery,
  useOnboardingAccountDataQuery,
} from '../../queries/energyStar'
import { useUserHasPermissionGroup } from '../../queries/permissions'
import { isCurrentUserRedaptiveDemoUser } from '../../utils/demo'
import {
  defaultQuerySearchParams,
  getUrlSearchParams,
} from '../../utils/getUrlSearchParams'
import { isVariantActive } from '../../utils/variants'
import FlagAlert from '../Icons/FlagAlert'
import GlobeIcon from '../Icons/GlobeIcon'
import SignOutIcon from '../Icons/SignOutIcon'

type Title = {
  component: 'title'
  label: string
}
type Divider = {
  component: 'divider'
  label: string
}
type NavLink = {
  badge?: PolymorphicComponentProps<'div', BadgeProps>
  component: 'navLink'
  gainsightId: string
  icon?: JSX.Element
  isActiveMatcher?: RegExp
  isHidden?: boolean
  label: string
  to?: string
}
export type ParentNavLink = NavLink & {
  children?: NavLink[]
}

export type MenuItem = Title | Divider | ParentNavLink

type MainNavProps = {
  expanded: boolean
  toggle: () => void
}

export const PendingActionIcon = styled.div<{ ml?: number }>`
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 7px;
  ${({ ml }) => ml && `margin-left: ${ml}px`}
`

const pendingActionBadge = {
  children: <PendingActionIcon />,
  color: 'transparent',
}

const NewMainNav = ({ toggle, expanded }: MainNavProps) => {
  // Gainsight widget custom styles override
  const GlobalStyle = createGlobalStyle`
    #apt-widget #apt-widget-controller {
      inset: auto auto 52px 17px !important
    }
    #apt-widget button {
      background: var(--mantine-color-blue-0);
      border-radius: 8px;
      color: var(--mantine-color-gray-8);
      cursor: pointer;
      font-weight: 600;
      height: 38px;
      line-height: 1;
      padding: 0;
      vertical-align: middle;
    }
    ${
      expanded
        ? `
        #apt-widget button {
          font-size: 14px;
          padding-left: 16px;
          text-align: left;
          width: 239px;
        }
        #apt-widget button:after {
          content: 'Need some help?';
        }
    `
        : `
        #apt-widget button {
          font-size: 18px;
          text-align: center;
          width: 38px;
        }
        #apt-widget button:after {
          content: '?';
        }
        `
    }
  `

  const { currentCustomerId } = useCurrentCustomerStore()

  const esSitesCountQuery = useEnergyStarSitesCountQuery()
  const onboardingDataQuery = useOnboardingAccountDataQuery()

  const { data: allProjectsModuleCustomersById } =
    useFetchAllProjectsModuleCustomers()
  const { data: allProjectsPostNtpModuleCustomersById } =
    useFetchAllProjectsPostNtpModuleCustomers()

  const opportunitiesEnabledCustomer =
    !isCurrentUserRedaptiveDemoUser() &&
    Object.keys(allProjectsModuleCustomersById ?? []).includes(
      currentCustomerId
    )

  const allProjectsPostNtpEnabledCustomer =
    !isCurrentUserRedaptiveDemoUser() &&
    Object.keys(allProjectsPostNtpModuleCustomersById ?? []).includes(
      currentCustomerId
    )
  const { data: userHasProjectPermissions } = useUserHasPermissionGroup('user')

  const { data: userIsMIT } = useUserHasPermissionGroup(MIT_GROUP_ID)

  const { items: sites } = useSelector(selectSitesEntity)
  const [searchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams)
  const siteId = queryParams.site ?? ''

  const navContent: MenuItem[] = [
    { component: 'title', label: 'Manage' },
    {
      component: 'navLink',
      label: 'Portfolio',
      icon: <BuildingIcon size={18} />,
      isActiveMatcher: /\/portfolio\/*/,
      gainsightId: 'primary-nav-portfolio',
      children: [
        {
          component: 'navLink',
          label: 'Sites',
          to: PORTFOLIO_SITES_PATH,
          badge: { children: 'New', color: 'green' },
          gainsightId: 'secondary-nav-portfolio-sites',
        },
        {
          component: 'navLink',
          label: 'Consumption',
          to: `${PORTFOLIO_CONSUMPTION_PATH}?${getUrlSearchParams(
            {
              period: Period.LAST_6_MONTHS,
            },
            TSSiteTabs.PORTFOLIO_CONSUMPTION,
            sites,
            true
          )}`,
          gainsightId: 'secondary-nav-portfolio-consumption',
        },
        {
          component: 'navLink',
          label: 'Sustainability Reporting',
          to: SUSTAINABILITY_REPORTING_PATH,
          isHidden: !esSitesCountQuery.data?.isEnabled,
          gainsightId: 'secondary-nav-portfolio-sustainability-reporting',
          ...(onboardingDataQuery.data?.numberOfInactiveProperties > 0 && {
            badge: pendingActionBadge,
          }),
        },
        {
          component: 'navLink',
          label: 'Benchmarking',
          to: SUSTAINABILITY_BENCHMARKING_PATH,
          isHidden: !esSitesCountQuery.data?.isEnabled,
          gainsightId: 'secondary-nav-portfolio-sustainability-benchmarking',
          badge: { children: 'Beta', color: 'blue.4' },
        },
      ],
    },
    {
      component: 'navLink',
      label: 'Projects',
      icon: <LayerGroupIcon size={18} />,
      isActiveMatcher: /\/projects\/*/,
      gainsightId: 'primary-nav-projects',
      children: [
        {
          component: 'navLink',
          label: 'All Projects',
          to: PROJECTS_PATH,
          isHidden: !opportunitiesEnabledCustomer,
          gainsightId: 'secondary-nav-projects-all-projects',
          badge: { children: 'Beta', color: 'blue.4' },
        },
        ...(isVariantActive('TEAL-1480')
          ? [
              {
                component: 'navLink' as NavLink['component'],
                label: 'All Projects - POST NTP',
                to: ALL_PROJECTS_POST_NTP_PATH,
                isHidden: !allProjectsPostNtpEnabledCustomer,
                gainsightId: 'secondary-nav-projects-all-projects-post-ntp',
              },
            ]
          : []),
        {
          component: 'navLink',
          label: 'Performance',
          to: `${PROJECTS_PERFORMANCE_PATH}?${getUrlSearchParams(
            {
              ...defaultQuerySearchParams,
              period: Period[queryParams.period] || Period.LAST_6_MONTHS,
              site: siteId,
              fromDate: queryParams.fromDate,
              toDate: queryParams.toDate,
            },
            TSSiteTabs.PROJECTS_PERFORMANCE,
            sites,
            true
          )}`,
          isHidden: !userHasProjectPermissions,
          gainsightId: 'secondary-nav-projects-performance',
        },
        {
          component: 'navLink',
          label: 'Invoices',
          to: `/projects/invoices?${getUrlSearchParams(
            {
              ...defaultQuerySearchParams,
              period: Period[queryParams.period] || Period.LAST_6_MONTHS,
              site: siteId,
              fromDate: queryParams.fromDate,
              toDate: queryParams.toDate,
            },
            TSSiteTabs.PROJECTS_INVOICE,
            sites,
            true
          )}`,
          isHidden: !userHasProjectPermissions,
          gainsightId: 'secondary-nav-projects-invoices',
        },
        {
          component: 'navLink',
          label: 'Meter Installation Tracker',
          to: `/projects/meter-installation-tracker?${getUrlSearchParams(
            {},
            TSSiteTabs.METER_INSTALLATION_TRACKER,
            sites,
            true
          )}`,
          isHidden: !userIsMIT,
          gainsightId: 'secondary-nav-projects-meter-installation-tracker',
        },
      ],
    },
    { component: 'divider', label: 'insights-divider' },
    { component: 'title', label: 'Insights' },
    {
      component: 'navLink',
      label: 'Data Explorer',
      to: CONSUMPTION_ANALYSIS_PATH,
      icon: <GlobeIcon size={18} />,
      isActiveMatcher: /\/data-explorer\/*/,
      gainsightId: 'primary-nav-data-explorer',
    },
    // Add the Alerts item conditionally right after Data Explorer
    ...(isVariantActive('YLW-1450')
      ? [
          {
            component: 'navLink' as NavLink['component'],
            label: 'Alerts',
            to: ALERTS,
            icon: <FlagAlert size={18} />,
            isActiveMatcher: /\/alerts\/*/,
            gainsightId: 'primary-nav-alerts',
          },
        ]
      : []),
    { component: 'divider', label: 'more-divider' },
    { component: 'title', label: 'Account' },
    {
      icon: <SlidersIcon size={18} />,
      component: 'navLink',
      label: 'Settings',
      to: SETTINGS_PATH,
      gainsightId: 'primary-nav-settings',
    },
    {
      icon: <SignOutIcon height={18} width={18} />,
      component: 'navLink',
      label: 'Log Out',
      to: '/logout',
      gainsightId: 'primary-nav-log-out',
    },
  ]

  return (
    <>
      <GlobalStyle />
      <NavToggleButton expanded={expanded} toggle={toggle} />
      {expanded ? (
        <ExpandedNavContent navContent={navContent} />
      ) : (
        <CollapsedNavContent navContent={navContent} />
      )}
    </>
  )
}

export default NewMainNav
